import React from 'react';
import { Collapse } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import WancloudsLogo from '../../../images/icons/wanclouds-logo';
import MenuIcon from '../../../images/icons/MenuIcon';
import ArrowIcon from '../../../images/icons/ArrowIcon';

const drawerWidth = 240;

const useStyles = makeStyles(({ palette, ...theme }) => ({
  background: {
    background: '#ffffff',
    position: 'fixed',
    width: '100%',
    zIndex: '888',
    height: '50px',
    color: '#fff',
  },
  button: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '16px',
    fontSize: '16px',
    border: 'none',
    '&:hover': {
      dropdown: {
        display: 'block',
      },
    },
  },
  listNav: {
    width: '100%',
    maxWidth: 360,
  },
  root: {
    display: 'flex',
    marginTop: '250px',
    position: 'fixed',
    zIndex: '9999',
  },
  appBar: {
    zIndex: '9999',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MobileHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [listOpen, setListOpen] = React.useState(true);

  const handleClick = () => {
    setListOpen(!open);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Typography variant="h6" noWrap className={classes.title}>
              <a href="https://wanclouds.net/">
                <span className="mt-2">
                  <WancloudsLogo color="#fff" width="125" />
                </span>
              </a>
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.listNav}
          >

            <ListItem button onClick={handleClick}>
              <ListItemText primary="OFFERINGS" className="text-black" />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <a href="https://wanclouds.net/draas"><ListItemText primary="Disaster Recovery as a service" /></a>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <a href="https://wanclouds.net/maas"><ListItemText primary="Migration as a service" /></a>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <a href="https://wanclouds.net/services"><ListItemText primary="Ongoing Services" /></a>
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleClick}>
              <ListItemText primary="PRODUCTS" />
              {listOpen && <ArrowIcon style={{ fontSize: '10px', transform: 'rotate(90deg)' }} />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <a href="https://wanclouds.net/vpc"><ListItemText primary="VPC +" /></a>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <a href="https://wanclouds.net/netorc"><ListItemText primary="NetOrc" /></a>
                </ListItem>
              </List>
            </Collapse>

            <ListItem button>
              <a href="https://wanclouds.net/about"><ListItemText primary="ABOUT" /></a>
            </ListItem>
            <ListItem button>
              <a href="https://wanclouds.net/contact-us"><ListItemText primary="CONTACT US" /></a>
            </ListItem>
          </List>
        </Drawer>
      </div>
    </>
  );
};

export default MobileHeader;
